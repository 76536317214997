<template>
  <div :class="['page', { isOld: isOld == 1 ? true : false }]">
    <div class="banner">
      <img :src="detail.image" alt="" />
    </div>
    <div class="name">
      <div class="name-txt">{{ detail.foodName }}</div>
      <div class="price"><span>¥</span>{{ detail.price }}</div>
    </div>
    <div class="title">菜品介绍</div>
    <div class="content">
      {{ detail.content }}
    </div>
    <div class="btn" @click="addToCar">添加到购物车</div>
  </div>
</template>

<script>
import { handleImg } from "@/utils/utils.js";
import { mapState } from "vuex";
import { getFoodDetailsUrl, addCartUrl } from "./api";
export default {
  name: "foodDetail",
  data() {
    return {
      isOld: localStorage.getItem("isOld"),
      detail: {},
      checkIsBindingFlag: undefined, // 1 表示老年人 反之则非老年人
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    this.checkIsBindingFlag = this.$route.query.checkIsBindingFlag;
    let params = {
      id: this.$route.query.id,
    };
    this.$axios.get(`${getFoodDetailsUrl}`, { params: params }).then((res) => {
      if (res.code == 200) {
        this.detail = res.data;
        this.detail.image = handleImg(750, 398, this.detail.image);
      }
    });
  },
  methods: {
    // 选取至购物车
    addToCar() {
      if (this.checkIsBindingFlag != 1) {
        this.$toast("请进行老年人认证！");
        return;
      }
      let params = {
        userId: this.userId,
        foodId: this.detail.id,
        foodNum: 1,
        tenantId: this.tenantId,
      };
      this.$axios.post(`${addCartUrl}`, params).then((res) => {
        if (res.code == 200) {
          this.$toast("添加到购物车成功~");
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.page {
  min-height: 100vh;
  box-sizing: border-box;
  .btn {
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #ffde6d 0%, #ffd032 100%);
    border-radius: 10px;
    line-height: 66px;
    text-align: center;
    font-size: 36px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    position: fixed;
    bottom: 110px;
    left: 50%;
    transform: translateX(-50%);
  }
  .content {
    padding: 0 30px;
    box-sizing: border-box;
    font-size: 32px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    word-break: break-all;
  }
  .title {
    font-size: 36px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    padding: 0 30px 36px;
    box-sizing: border-box;
  }
  .name {
    font-size: 40px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    display: flex;
    align-items: center;
    padding: 30px;
    box-sizing: border-box;
    .price {
      font-size: 48px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #e02020;
      span {
        font-size: 24px;
      }
    }
    .name-txt {
      flex: 1;
    }
  }
  .banner {
    width: 100%;
    height: 398px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.isOld {
  .name {
    font-size: 40px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    display: flex;
    align-items: center;
    padding: 30px;
    box-sizing: border-box;
    .price {
      font-size: 58px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #e02020;
      span {
        font-size: 34px;
      }
    }
    .name-txt {
      flex: 1;
      font-size: 44px;
    }
  }
  .btn {
    width: 550px;
    height: 86px;
    background: linear-gradient(180deg, #ffde6d 0%, #ffd032 100%);
    border-radius: 10px;
    line-height: 86px;
    text-align: center;
    font-size: 46px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    position: fixed;
    bottom: 110px;
    left: 50%;
    transform: translateX(-50%);
  }
  .content {
    padding: 0 30px;
    box-sizing: border-box;
    font-size: 42px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    word-break: break-all;
  }
  .title {
    font-size: 46px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    padding: 0 30px 36px;
    box-sizing: border-box;
  }
}
</style>
